import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/alirun/Work/Blockeys/ofi-landing/src/layouts/blogPost.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The season is changing, and so are we: As summer comes to a close, the team here at Opium are reflecting on our many successes in terms of developments and product updates, and we are optimistically looking forward to next season.`}</p>
    <p>{`This month’s highlights are as follows:`}</p>
    <h1 {...{
      "id": "product-updates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#product-updates",
        "aria-label": "product updates permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Product Updates`}</h1>
    <h2 {...{
      "id": "reaitco-decentralized-protection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#reaitco-decentralized-protection",
        "aria-label": "reaitco decentralized protection permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ReaIT.co Decentralized Protection`}</h2>
    <p>{`A game-changing development has occurred on the ReaIT.co platform; users can finally get protected by Opium’s decentralized insurance, meaning properties on the platform can access coverage for more than just water and fire damage.`}</p>
    <p>{`Now, thanks to Opium, ReaIT members can rest easy knowing that their cash flow is being protected because, with Opium decentralized insurance, landlords will continue to receive rental income when their tenant fails to pay.`}</p>
    <p>{`To access ReaIT Insurance, you must be a KYC-approved user of ReaIT and a non-US citizen.`}</p>
    <p>{`Staking for RealT Insurance is available (only for non-US citizens) here:`}</p>
    <p><a parentName="p" {...{
        "href": "https://app.opium.finance/eth/staking"
      }}>{`Opium.Finance - Staking`}</a>{` `}</p>
    <p>{`Further information about these topics can be found here:`}</p>
    <p><a parentName="p" {...{
        "href": "https://medium.com/opium-network/first-decentralised-protection-for-realt-no-occupancy-event-was-launched-on-opium-protocol-9feeedf6b5cd"
      }}>{`First Decentralised Protection for RealT “no-occupancy” event was launched on Opium Protocol`}</a>{` `}</p>
    <h2 {...{
      "id": "governance-updates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#governance-updates",
        "aria-label": "governance updates permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Governance Updates`}</h2>
    <p>{`In August, the Opium governance forum was buzzing with activity, with several important topics being discussed.`}</p>
    <p>{`Opium users have called for improvement and an increased level of clarity regarding the proposal process.`}</p>
    <p>{`For example, it has been suggested that `}<em parentName="p">{`proposals should stick to one single issue`}</em>{` to avoid creating a convoluted discussion about several topics.`}</p>
    <p>{`Also, the author of a proposal now holds the responsibility of facilitating discussion and engagement until a solution has been reached.`}</p>
    <p>{`See the full conversation here:`}</p>
    <p><a parentName="p" {...{
        "href": "https://forum.opium.network/t/discussion-community-voting-principles/257"
      }}><strong parentName="a">{`Discussion: Community voting principles`}</strong></a></p>
    <p>{`Some great optimization proposals have also been eagerly discussed, such as the `}<em parentName="p">{`idea of reducing the minimum required bond for proposal validation`}</em>{` and adding an `}<em parentName="p">{`additional layer of security to Opium DAO`}</em>{`, which would create a more effective defense against malicious and spam proposals.`}</p>
    <p>{`You can join in these discussions here:
`}<a parentName="p" {...{
        "href": "https://forum.opium.network/t/proposal-additional-security-layer-for-opium-dao/262"
      }}>{`Proposal: Additional security layer for Opium DAO`}</a>{` `}</p>
    <p>{`And here:`}</p>
    <p><a parentName="p" {...{
        "href": "https://forum.opium.network/t/proposal-decrease-minimum-required-bond-for-proposals-validation/263"
      }}>{`Proposal: Descrease minimum required bond for proposals validation`}</a></p>
    <p>{`The most important discussion to unfold on the forum was about the `}<strong parentName="p">{`Opium Protocol Version 2.0.`}</strong></p>
    <p>{`This upgrade will have a significant impact from the user’s perspective because the Opium Protocol V2 is designed to allow interoperability with the wider DeFi ecosystem.`}</p>
    <p>{`This means more parameters will be delegated to the governance logic of the protocol, such as the fees for the execution of derivatives, the period during which a position can be canceled, the proposal of new timelocks, and token spenders.`}</p>
    <p>{`You can bring yourself up to speed on all things Opium Protocol Version 2.0 via the following link:`}</p>
    <p><a parentName="p" {...{
        "href": "https://forum.opium.network/t/discussing-opium-2-0/269"
      }}>{`Discussion: Opium 2.0`}</a></p>
    <p>{`We welcome all ideas, opinions, and proposals, and we would love for you to join the Opium Governance forum at:`}</p>
    <p><a parentName="p" {...{
        "href": "https://forum.opium.network/"
      }}><strong parentName="a">{`Opium Governance Forum`}</strong></a>{` `}</p>
    <p>{`See existing proposals, create a proposal or discuss existing ideas with Opium and the Opium community and see how you can make a difference.`}</p>
    <h1 {...{
      "id": "we-are-hiring",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#we-are-hiring",
        "aria-label": "we are hiring permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`We Are Hiring!`}</h1>
    <p>{`If you are talented, ambitious, and have what it takes to contribute to Opium Team, check out the following openings:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Technical manager`}</strong>{`. More info `}<a parentName="p" {...{
            "href": "https://opium-team.notion.site/Technical-manager-areers-at-Opium-ce17ed41c8744e2ca0c28679f6e18783"
          }}>{`here`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Chief Marketing Officer`}</strong>{`. More info `}<a parentName="p" {...{
            "href": "https://opium-team.notion.site/Chief-Marketing-Officer-areers-at-Opium-fc70d6dfb0724ea98f51a1817598a914"
          }}>{`here`}</a></p>
      </li>
    </ul>
    <p>{`If you think you can help us grow the protocol and its surrounding ecosystem, please submit your CV and a cover letter to: `}<a parentName="p" {...{
        "href": "mailto:hi@opium.team"
      }}>{`hi@opium.team`}</a></p>
    <h2 {...{
      "id": "looking-forward",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#looking-forward",
        "aria-label": "looking forward permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Looking Forward`}</h2>
    <p>{`We never sit still here at Opium, and you can stay up to date with our constant progress through the `}<a parentName="p" {...{
        "href": "https://t.me/OpiumFinance"
      }}>{`Opium news channel`}</a>{`.`}</p>
    <p>{`If you have some ideas or requests relating to the improvement of the app.opium.finance interface, please share your thoughts here:`}</p>
    <p><a parentName="p" {...{
        "href": "https://opium.canny.io/opium-finance-feature-request"
      }}>{`https://opium.canny.io/opium-finance-feature-request`}</a></p>
    <h1 {...{
      "id": "about-opium",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#about-opium",
        "aria-label": "about opium permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`About Opium`}</h1>
    <p>{`Opium Protocol is a universal and robust DeFi protocol that allows for creating, settling, and trading decentralized derivatives.`}</p>
    <p><a parentName="p" {...{
        "href": "https://app.opium.finance/"
      }}><strong parentName="a">{`Try out Opium Finance`}</strong></a>{` or `}<a parentName="p" {...{
        "href": "https://docs.opium.network/"
      }}><strong parentName="a">{`Explore Opium Protocol.`}</strong></a></p>
    <p>{`Stay informed and follow `}<a parentName="p" {...{
        "href": "https://twitter.com/Opium_Network"
      }}><strong parentName="a">{`Opium.Team on Twitter`}</strong></a>{`.`}</p>
    <p>{`Did you know that you can subscribe to our `}<a parentName="p" {...{
        "href": "https://t.me/OpiumFinance"
      }}><strong parentName="a">{`News Channel`}</strong></a>{` to get more exciting news delivered to your morning coffee?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      